import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services.js'
import Products from './pages/Products.js'
// import Clients from './pages/Clients.js'
import ContactUs from './pages/ContactUs'

import Pagenotfound from './pages/Pagenotfound'

import ScrollToTop from './components/ScrollToTop.jsx'
import Industries from './pages/Industries.js'
import Career from './pages/Career.js'
import DeveloperPartnership from './pages/DeveloperPartnership.js'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />{' '}
          <Route path='/about' element={<About />} />{' '}
          <Route path='/services' element={<Services />} />{' '}
          <Route path='/contactus' element={<ContactUs />} />{' '}
          <Route path='/products' element={<Products />} />{' '}
          <Route
            path='/developerpartnership'
            element={<DeveloperPartnership />}
          />{' '}
          <Route path='/industries' element={<Industries />} />{' '}
          <Route path='/career' element={<Career />} />{' '}
          <Route path='*' element={<Pagenotfound />} />{' '}
        </Routes>{' '}
        <ScrollToTop />
      </BrowserRouter>{' '}
    </div>
  )
}

export default App
