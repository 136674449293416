// import React from 'react'
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/CareerN.jpg'
import Career1 from '../Images/Vedsi Company Pics/Career1.jpg'
import {
  Container,
  TextField,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Link } from 'react-router-dom'
import ButtonBase from '@mui/material/ButtonBase'
import '../styles/CareerStyle.css'
import SendIcon from '@mui/icons-material/Send'

// import CarrerBlue from '../Images/Vedsi Company Pics/Blue.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

function Career() {
  // const Career = () => {
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1 className='heading' data-aos='fade-right'>
          {' '}
          Current Job Openings{' '}
        </h1>{' '}
        <br />{' '}
        <h5 data-aos='fade-left' data-aos-delay='200'>
          {' '}
          Currently No Vacancy!!!!!!!!!{' '}
        </h5>{' '}
      </Container>{' '}
      <br />
      {/* <Box sx={{ bgcolor: '' }}>
        <div
        // className='career'
        // style={{ backgroundImage: `url(${CarrerBlue})` }}
        >
          <div className='headerContainer'>
            <h1 className='start'> Start your journey with us </h1> <br />
            <Link to='/ContactUs'>
              {' '}
              <Button type='submit' variant='contained' className='start'>
                {' '}
                Contact Us{' '}
              </Button>{' '}
            </Link>{' '}
          </div>{' '}
        </div>{' '}
      </Box>{' '} */}
      <Box>
        <Container className='Project-background'>
          <Grid container item xs={12} spacing={1}>
            <Grid
              item
              xs={8}
              sm={12}
              data-aos='fade-right'
              data-aos-delay='200'
              sx={{
                fontSize: '3rem',
                '@media (max-width:600px)': {
                  fontSize: '1.3rem',
                },
              }}
            >
              Start your journey with us
            </Grid>
            <Grid item xs={4} sm={12} data-aos='fade-left' data-aos-delay='200'>
              <Link to='/contactus'>
                {' '}
                <Button
                  variant='contained'
                  endIcon={<SendIcon />}
                  sx={{
                    // fontSize: '3rem',
                    '@media only screen and (max-width:600px)': {
                      //
                      // display: 'none',
                      fontSize: '0.7rem',
                    },
                  }}
                >
                  CONTACT US
                </Button>
              </Link>{' '}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <br />
      <Box className='Grid-career '>
        <Typography
          data-aos='zoom-in'
          sx={{
            fontSize: '3.5rem',
            textAlign: 'center',
            marginBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          {' '}
          Why Vedsi ?{' '}
        </Typography>{' '}
        <Container>
          <p className='Home-heading' data-aos='zoom-in' data-aos-delay='200'>
            We know that experts are committed to finding an organization or
            company that will excite and challenge experts while supporting your
            growth and your expertise. Vedsi provides a gratifying environment
            in the workplace.{' '}
          </p>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <Container>
        <Grid
          container
          spacing={12}
          direction='column'
          // sx={{ '@media only screen and (min-width: 768px)': ' padding:0px' }}
        >
          <Grid container item xs={12} spacing={8}>
            {' '}
            {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
            <Grid item data-aos='zoom-out'>
              <ButtonBase
                sx={{
                  width: 450,
                  height: 450,
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 300,
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={Career1}
                  sx={{
                    '@media (max-width:600px)': {
                      padding: 0,
                      // '& h4': {
                      //   fontSize: '1rem',
                      // },
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='zoom-out'
              data-aos-delay='200'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2.5rem',
                  textAlign: 'center',
                  marginTop: '10px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Why choose a career with Vedsi ?{' '}
              </Typography>{' '}
              <br /> {/* <p className='Home-heading'> */}{' '}
              <ul data-aos='zoom-out-right'>
                <li> Opportunity to collaborate with the best team members </li>{' '}
                <li> Training and guidance from senior employees </li>{' '}
                <li>
                  The ability to enhance your skills with new and emerging
                  technologies{' '}
                </li>{' '}
                <li> Opportunity to enhance your ability and skills </li>{' '}
                <li> Opportunity to do meaningful work with agile teams </li>{' '}
              </ul>{' '}
              {/* </p>{' '} */} <br />
              <br />
              <Link to='/about'>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='success'
                  data-aos='zoom-out-right'
                  data-aos-delay='100'
                >
                  {' '}
                  Learn More{' '}
                </Button>{' '}
              </Link>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Grid>{' '}
      </Container>{' '}
      <br />
      <br />
      <Container
        sx={{ fontSize: '1.4em', textAlign: 'justify', lineHeight: '2' }}
      >
        <p data-aos='zoom-in'>
          Vedsi offers an attractive and unique opportunity to be part of an
          exceptional team. We are one of the leading and fastest-growing
          company in the digital world. Our goal is to help each and every
          individuals reach their full potential so that they are able to
          enhance their full potential. Vedsi has a very clear mission and
          vision to enhance the digital world.
        </p>
        <br />
        <p data-aos='zoom-in' data-aos-delay='100'>
          Our employees help us define and enable the exponential future with
          market - leading enterprise solutions for digital transformation. We
          are also committed to fostering the best possible work environment for
          our people in each of our locations. Team socials, volunteer events,
          excellent technology, and supportive co-workers come together at Vedsi
          to create a winning team.
        </p>{' '}
        <br />
        <p data-aos='zoom-in' data-aos-delay='200'>
          The diversity and strength of our workforce are fundamental to our
          success. People are our greatest strength. We strive to attract and
          retain the very best talent the industry has to offer.
        </p>{' '}
      </Container>{' '}
      <br />
      <br />
      <Box className='Card-bg'>
        <br />
        <Card
          data-aos='fade-right'
          data-aos-delay='200'
          style={{
            maxWidth: 500,
            margin: '50px auto',
            padding: '20px 5px',
            boxShadow: 3,
          }}
        >
          <CardContent>
            <Typography variant='h6' gutterBottom>
              {' '}
              REQUEST A QUOTE{' '}
            </Typography>{' '}
            <Typography gutterBottom variant='body3' component='p'>
              Please fill your details give below.{' '}
            </Typography>{' '}
            <br />
            <FormControl>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField
                    label='Contact Persons Name'
                    placeholder='Contact Persons Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='email'
                    label='Email'
                    placeholder='Enter email'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='number'
                    label='Contact Number'
                    placeholder='Enter phone number'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    label='Company Name'
                    placeholder='Company Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <Button
                    component='label'
                    variant='contained'
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file <VisuallyHiddenInput type='file' />
                  </Button>{' '}
                </Grid>{' '}
                <br />
                <Grid xs={12} item>
                  <TextField
                    label='Your Message'
                    multiline
                    rows={4}
                    placeholder='Type your message'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <br /> <br />
                <Grid
                  xs={12}
                  item
                  sx={{ justifyContent: 'center', textAlign: 'center' }}
                >
                  <Button
                    type='submit' //variant='contained' fullWidth>
                    variant='contained'
                    size='large'
                    // sx={{ justifyContent: 'center', textAlign: 'center' }}
                  >
                    {' '}
                    Submit{' '}
                  </Button>{' '}
                </Grid>{' '}
              </Grid>{' '}
            </FormControl>{' '}
          </CardContent>{' '}
        </Card>{' '}
        <br />
      </Box>{' '}
    </Layout>
  )
}

export default Career
