// import React from 'react'
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/IndustryN.jpg'
import Industry1 from '../Images/Vedsi Company Pics/Industry1.png'
import { Container, Box } from '@mui/material'
import '../styles/Industries.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Blogs = () => {
  useEffect(() => {
    Aos.init({
      duration: 2000,
    })
  }, [])

  return (
    <Layout>
      <div
        className='Industries-banner'
        style={{ backgroundImage: `url(${Banner})` }}
      >
        {' '}
        {/* <div>
          <h1 className='Industries-title'> INDUSTRIES </h1>{' '}
        </div>{' '} */}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1
          className='Main-heading-title'
          data-aos='fade-left'
          data-aos-delay='100'
        >
          {' '}
          Now we are the Future{' '}
        </h1>{' '}
        <br />
        <p className='Home-heading' data-aos='fade-right' data-aos-delay='200'>
          {' '}
          Nowadays, businesses need more attention to invest more in web design
          and development for their own benefit, which is directly reflected on
          their website. Some benefits of web design and development for your
          business are: sales growth, competitive SEO, user-friendly websites,
          generating long-term customer relationships, accessibility to larger
          audiences, well-maintained consistency, up-to - date marketing
          strategies, branding authenticity, etc. To enhance the above benefits
          for industries, web design is a critical field across numerous
          industries, including advertising and marketing, automobiles, computer
          systems design, corporate governance, education, finance and
          insurance, health care, manufacturing, retail trade, real estate,
          software publishing, etc.
          <br />
        </p>{' '}
      </Container>{' '}
      <Box>
        <div
          className='Industry-app-bg '
          style={{ backgroundImage: `url(${Industry1})` }}
        >
          <div className='Industry-text'>
            <h1> Industries Served </h1>{' '}
          </div>{' '}
        </div>{' '}
      </Box>{' '}
      <Box className='Industry-bg'>
        <br />
        <Container>
          <p>
            <ul className='Industry-benefits'>
              <li data-aos='fade-right' data-aos-delay='100'>
                <b> Digital Marketing: </b>
                In the digital era, the digital marketing industry fully depends
                on web design and development to create aesthetically pleasing
                websites for their clients. They rely on web designers and
                developers to create campaigns that align with the client’s
                branding, mission, and values. In the current scenario, many
                businesses and brands have realized the importance of digital
                marketing and have started redesigning their websites as well.{' '}
              </li>{' '}
              <br />
              <li data-aos='fade-left'>
                <b> Automobile: </b> A well-designed automotive website
                influences customer behavior and increases business growth. More
                than 60 % of customers research cars online, investing in a
                professional, visually appealing, user-friendly website. In the
                recent scenario of automotive web design, emerging trends
                continually enhance user experiences.{' '}
              </li>{' '}
              <br />
              <li data-aos='zoom-in'>
                <b> Computer Systems Design: </b> Computer systems design is
                another industry that highly depends on web design and
                development skills. Many companies in this field may employ Web
                Designers to create their clients ' websites, apps, and software
                programs. Without web designers and developers, many companies
                would be unable to keep up with the ever-growing demand for
                digital services.{' '}
              </li>{' '}
              <br />
              <li data-aos='zoom-out'>
                <b> Corporate Goveranace: </b> Corporate governance web design
                is another resource for communicating information to
                stackeholders.They facilitate timely information disclosures,
                which leads to greater transparency and can help to improve
                investor perceptions. There is a need for attention to post
                information on corporate websites in multimedia(audio / video /
                text / graphics) and multiple languages.{' '}
              </li>{' '}
              <br />
              <li data-aos='fade-up'>
                <b> Education: </b> Nowadays, the education industry needs to
                have responsive education websites since they are often the
                first point of contact for students and parents. A custom web
                design website can improve the overall image and reputation of
                an education organization, which can increase admissions. An
                updated, informative, and well-designed website will help your
                organization gain credibility. Educational organizations can
                easily enhance interaction with students and their parents
                through custom-design websites.{' '}
              </li>{' '}
              <br />
              <li data-aos='fade-down'>
                <b> Finance and Insurance: </b> The finance and insurance
                industries are very vast and the largest employers of web design
                and development professionals. These companies need the
                attention of websites to advertise their services, provide
                customer service, and process transactions. Web designers can
                help businesses in this field create successful websites and
                digital applications.{' '}
              </li>{' '}
              <br />
              <li data-aos='zoom-out'>
                <b> Health Care: </b> The health care industry is rapidly
                changing to fulfill the demands of patients. Web design has been
                so instrumental in helping people find health - related
                information, and keep medical records online, and share them
                with doctors whenever they need to see one. Doctors can also
                access online records of their patients whenever they need to
                treat a patient who has been referred by another doctor or
                hospital.{' '}
              </li>{' '}
              <br />
              <li data-aos='flip-up'>
                <b> Manufacturing: </b> In the current scenario, manufacturers
                recognize that websites are an important lead generation tool
                that drives sales and their business growth. They also play an
                important role in shaping customer perspectives on the brand,
                product quality, and customer service. Websites are the
                benchmark of a digital presence and are one of the most
                important marketing tools for the manufacturing sector.{' '}
              </li>{' '}
              <br />
              <li data-aos='flip-down'>
                <b> Retail Trade: </b> The retail trade also depends on web
                design and development. Many stores, both online and offline,
                need websites to attract customers and showcase their products.
                In the future scenario, the number of web design professionals
                in the retail trade is expected to grow. Over two million people
                are currently employed.{' '}
              </li>{' '}
              <br />
              <li data-aos='zoom-in-left'>
                <b> Real Estate: </b> A well-designed website for real estate is
                a crucial part nowadays, which keeps huge data on real estate,
                markets, and in-depth information at the operational level,
                displays the list of properties and services, and allows you to
                reach targeted customers. A website design serves as an online
                medium that can more efficiently and effectively advertise the
                mission, vision, services, and brand story that are essential to
                educating your potential customers. The real estate web design
                can be helpful for people to search for local listings, and find
                good offers, and easily find their dream homes.{' '}
              </li>{' '}
              <br />
              <li data-aos='zoom-in-right'>
                <b> Software Publishing: </b> Software publishing has become a
                booming industry with the advancement of technology and the
                enhancement of digital products. Related companies often rely on
                web designers to create user-friendly interfaces for their
                products.{' '}
              </li>{' '}
            </ul>{' '}
            <br />
          </p>{' '}
        </Container>{' '}
      </Box>{' '}
    </Layout>
  )
}

export default Blogs
