import React from 'react'
import { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/PartnershipN.jpg'
import { styled } from '@mui/material/styles'

import { Container, Grid, Box, ButtonBase, Typography } from '@mui/material'
import '../styles/DeveloperPartnership.css'
import PartnershipNew from '../Images/Vedsi Company Pics/PartnershipNew.jpg'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const DeveloperPartnership = () => {
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <Layout>
      <div
        className='Developer-banner '
        style={{ backgroundImage: `url(${Banner})` }}
      >
        {' '}
        {/* <div>
          <h1 className='Heading-title'> DEVELOPER PARTNERSHIP </h1>{' '}
        </div>{' '} */}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1
          className='Main-heading-title'
          data-aos='fade-left'
          // data-aos-delay='100'
        >
          {' '}
          Vedsi will be your new Developer Partner{' '}
        </h1>{' '}
        <br />
        <p className='Home-heading' data-aos='fade-right' data-aos-delay='200'>
          Now in the current scenario, the most critical element of the market
          is to create a trustable core team of members that can make sure of
          the unique needs of your business. After all, they will develop or
          build a product or a solution that will play a vital role in helping
          you achieve your business goals.A good developer partner will take the
          time to understand the entire procedure or the ins and outs of your
          business.{' '}
        </p>{' '}
      </Container>{' '}
      <Box className='Developer-grid'>
        <br />
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='zoom-in'
                data-aos-delay='100'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginBottom: '10px',
                    fontWeight: 'bolder',
                    fontFamily: 'Libre Baskerville, sans-serif',
                  }}
                >
                  {' '}
                  Developer Partner Activities{' '}
                </Typography>{' '}
                <br />
                <p p>
                  Our company consider the 4 Stages of Partner Development,
                  which gives graphic outlines of activities and outcomes that
                  should be pursued and measured for each partner development
                  stage.{' '}
                </p>{' '}
                <p>
                  <ul>
                    <li>
                      <b>Advise:</b>
                      We believe and communicate in the context of a
                      relationship so that messaging effectively reaches the
                      target clients.
                    </li>

                    <li>
                      <b>Acclimate:</b>
                      We educate you on your product capabilities and value
                      propositions that will increase business potential.
                    </li>

                    <li>
                      <b>Activate:</b>
                      We convert product knowledge to product/service
                      development activity.
                    </li>

                    <li>
                      <b>Accelerate:</b>
                      We socialize our partner products on internal/external
                      channels so that selling activity will extremely increase.
                    </li>
                  </ul>
                </p>
              </Grid>{' '}
              <Grid item>
                <ButtonBase
                  data-aos='zoom-in'
                  data-aos-delay='200'
                  sx={{
                    width: 500,
                    height: 400,
                    marginTop: '100px',
                    '@media (max-width:600px)': {
                      marginTop: '0px',
                      width: 345,
                      height: 250,
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={PartnershipNew}
                    sx={{
                      marginTop: '0px',
                      borderRadius: '15px',

                      '@media (max-width:600px)': {
                        padding: 0,
                        // '& h4': {
                        //   fontSize: '1rem',
                        // },
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
        <br />
      </Box>{' '}
    </Layout>
  )
}

export default DeveloperPartnership
