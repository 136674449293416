import React from 'react'
import Layout from '../components/Layout/Layout'
import {
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
// import Banner from '../Images/Home.jpeg'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/ProductN.jpg'
import Education from '../Images/Vedsi Company Pics/Education.jpg'
import '../styles/Product.css'

import Notifications from '../Images/Vedsi Company Pics/Products/Notifications.jpg'
import Notes from '../Images/Vedsi Company Pics/Products/Notes.jpg'
import MCQ from '../Images/Vedsi Company Pics/Products/MCQ.png'
import LiveClass from '../Images/Vedsi Company Pics/Products/LiveClass.png'
import Assignment from '../Images/Vedsi Company Pics/Products/Assignment.jpg'
import FeesMgmt from '../Images/Vedsi Company Pics/Products/FeesMgmt.png'

const Products = () => {
  return (
    <Layout>
      <div
        className='Product-banner'
        style={{ backgroundImage: `url(${Banner})` }}
      >
        {/* <div className='Heading-title' data-aos='fade-down'>
          <h1> Products </h1>{' '}
        </div>{' '} */}
        <div>
          <Link to='/ContactUs'>
            {' '}
            <Button
              type='submit'
              variant='contained'
              color='success'
              sx={{ margin: '20px', bgcolor: 'rgb(5, 20, 241)' }}
            >
              {' '}
              REQUEST A QUOTE{' '}
            </Button>{' '}
          </Link>{' '}
        </div>
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1 className='heading' data-aos='fade-left' data-aos-delay='100'>
          {' '}
          PRODUCTS{' '}
        </h1>{' '}
        <br />
        <p className='Home-heading' data-aos='fade-right' data-aos-delay='200'>
          Vedsi provides an online platform that contains all the features
          related to the online education system required for any type of esteem
          institute; coaching classes, tution, etc. You may now create and sell
          online courses containing coaching notes, videos, assignments, online
          tests, live classes, attendance, and fee management systems that are
          absolutely excellent for your institute.  
        </p>{' '}
        <br />
      </Container>{' '}
      <br />
      <Box>
        <div
          className='Industry-app-bg'
          style={{ backgroundImage: `url(${Education})` }}
        >
          <div className='Education-text'>
            <h1> Online Education System</h1>{' '}
          </div>{' '}
        </div>{' '}
      </Box>{' '}
      <Box>
        <Container
          sx={{
            my: 0,
            textAlign: 'center',
            p: 2,
            '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
            '& p': { textAlign: 'justify' },
            '@media (max-width:600px)': {
              mt: 0,
              '& h4': {
                fontSize: '1rem',
              },
            },
          }}
        >
          <h1
            className='application'
            data-aos='fade-left '
            data-aos-delay='100'
          >
            {' '}
            Online Application Features{' '}
          </h1>{' '}
          {/* <br /> */}
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '20px',
              className: 'CardPic',
              marginLeft: '5px',
              textAlign: 'center',
              '@media (max-width:600px)': {
                mt: 0,
                '& h4': {
                  fontSize: '0.7rem',
                  width: '100%',
                  marginLeft: 5,
                  paddingLeft: 7,
                  marginTop: '10px',
                },
              },
            }}
          >
            <Grid
              xs={12}
              md={4}
              sm={4}
              data-aos='fade-right'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Notifications}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Notifications
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We provide course-wise notifications for continuous
                      Interaction, improvement, reminders, and important notice.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              data-aos='fade-left'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Notes}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Notes and Videos
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We create PDF courses by making notes and uploading videos
                      for the students, which is very helpful in each and every
                      stage.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid xs={12} md={4} sm={4} data-aos='zoom-in' data-aos-delay='300'>
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={MCQ}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Online MCQ Tests
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We provide an online plateform for the MCQ test for
                      students with quick results to know the ability of the
                      brilliant students.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              data-aos='zoom-out'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={LiveClass}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Live Class
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We provide live classes with a white board and maintain
                      the attendance details of the students.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid xs={12} md={4} sm={4} data-aos='fade-up' data-aos-delay='200'>
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Assignment}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Assignments
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We provide a facility for assignment of subjects and
                      checking submissions by the students.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              data-aos='fade-down'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    marginRight: '25px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={FeesMgmt}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      Fees Management System
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      We provide a facility for fees management, installments
                      with auto reminders.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Box>
    </Layout>
  )
}

export default Products
