import React from 'react'
import { Box, Container, Grid, Typography, colors } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
// import InstagramIcon from '@mui/icons-material/Instagram'

// import YouTubeIcon from '@mui/icons-material/YouTube'

import { Link } from 'react-router-dom'
// import { styled } from '@mui/material/styles'
// import { Link } from 'react-router-dom'
import '../../styles/FooterStyles.css'

function Footer() {
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          fontFamily: '"Roboto Condensed", Sans-serif',
          // backgroundColor: 'ButtonShadow',
          backgroundColor: 'black',
          color: 'white',

          '@media': { paddingLeft: '10px' },
        }}
      >
        <Container>
          <Box
            sx={{
              textAlign: 'center',
              bgcolor: '#1A1A19',
              color: 'white',
              p: 0,
            }}
          >
            <br />
            <br />
            <Typography variant='h4'>Contact With Us</Typography>
            <Box
              className='Footer-box'
              sx={{
                background: colors.blue,

                p: { xs: 4, md: 10 },
              }}
            >
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ textAlign: 'justify' }}
                >
                  <Typography variant='h5' sx={{ margin: '15px' }}>
                    ABOUT US{' '}
                  </Typography>{' '}
                  <Typography
                    variant='h7'
                    sx={{
                      marginLeft: '30px',
                    }}
                  >
                    <p
                      className='para'
                      // sx={{
                      //   '@media (max-width:600px)': {
                      //     marginLeft: '20px',
                      //   },
                      // }}
                    >
                      We, Vedsi IT Solutions is a Software Development & Digital
                      Marketing Company focused on using technology to enhance
                      the business process.Vedsi have experienced and highly
                      quilified dynamic professionals with great expertise to
                      provide world - class Software Development & Digital
                      Transformation Company from Raipur.{' '}
                    </p>{' '}
                  </Typography>{' '}
                </Grid>{' '}
                <Grid
                  className='Footer-grid'
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{
                    textAlign: 'left',
                    paddingLeft: '60px',
                  }}
                  // sx={{
                  //   '@media (max-width:600px)': {
                  //     marginRight: '170px',
                  //   },
                  // }}
                >
                  <Typography variant='h5' sx={{ margin: '15px' }}>
                    QUICK LINKS{' '}
                  </Typography>{' '}
                  <Link to='/About' size='small' className='Footer-links'>
                    {' '}
                    About{' '}
                  </Link>{' '}
                  <br /> <br />
                  <Link to='/services' size='small' className='Footer-links'>
                    Services{' '}
                  </Link>{' '}
                  <br />
                  <br />
                  <Link to='/products' size='small' className='Footer-links'>
                    {' '}
                    Products{' '}
                  </Link>{' '}
                  <br /> <br />
                  <Link
                    to='/developerpartnership'
                    size='small'
                    className='Footer-links'
                  >
                    {' '}
                    Developer Partnership{' '}
                  </Link>{' '}
                  <br /> <br />
                  <Link to='/industries' size='small' className='Footer-links'>
                    {' '}
                    Industries{' '}
                  </Link>{' '}
                  <br /> <br />
                  <Link to='/Contactus' size='small' className='Footer-links'>
                    {' '}
                    Contact Us{' '}
                  </Link>{' '}
                </Grid>{' '}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{
                    textAlign: 'left',
                    fontSize: '16px',
                    lineHeight: '2',
                    // marginLeft: '15px',

                    '@media (max-width:600px)': {
                      fontSize: '16px',
                      textAlign: 'left',
                    },
                  }}
                >
                  <Typography variant='h5' sx={{ margin: '15px' }}>
                    OUR SERVICES{' '}
                  </Typography>{' '}
                  <Box sx={{ marginLeft: '15px' }}>
                    <p>Website Development</p>
                    <p>Software Development</p>
                    <p>Digital Marketing</p>
                    <p>Mobile Appication</p>
                    <p>E-Commerce Website</p>
                    <p>Search Engine Optimization</p>
                    <p>Facebook Marketing</p>
                    <p>Consulting Services</p>
                    <p> Artificial Intelligence</p>
                    <p>Machine Learning</p>
                    <p>Payroll Outsourcing</p>
                  </Box>
                  {/* <Typography variant='h7' sx={{ margin: '15px' }}>
                    Website Development{' '}
                  </Typography>{' '}
                  <Typography variant='h7' sx={{ margin: '15px' }}>
                    Software Development{' '}
                  </Typography>{' '}
                  <Typography variant='h7' sx={{ margin: '15px' }}>
                    Digital Marketing{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    {' '}
                    Mobile Appication{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    eCommerce Website{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Search Engine
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Facebook Marketing{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Consulting Services{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Artificial Intelligence
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Machine Learning{' '}
                  </Typography>{' '}
                  <Typography variant='7' sx={{ margin: '15px' }}>
                    Payroll Outsourcing{' '}
                  </Typography>{' '} */}
                </Grid>{' '}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{ textAlign: 'start' }}
                >
                  <Typography variant='h5' sx={{ margin: '15px' }}>
                    QUICK CONTACT{' '}
                  </Typography>{' '}
                  <Typography variant='h6' sx={{ margin: '15px' }}>
                    Register Office Address:
                  </Typography>{' '}
                  <Typography
                    sx={{
                      margin: '15px',
                      textAlign: 'justify',
                      fontSize: '15px',
                    }}
                  >
                    Vedsi IT Solutions Pvt Ltd, H.No - 39, Natural City,
                    Raipur(C.G.), PIN: 492001.{' '}
                  </Typography>{' '}
                  <Typography variant='h6' sx={{ margin: '15px' }}>
                    {' '}
                    Contact Number:
                  </Typography>{' '}
                  {/* <Typography variant='h8' sx={{ margin: '15px' }}>
                    Land Line: 0771 - 2243022{' '}
                  </Typography>{' '} */}
                  <Typography variant='h8' sx={{ margin: '15px' }}>
                    Mobile: 7879957062 <br />
                  </Typography>{' '}
                  <Typography variant='h8' sx={{ margin: '15px' }}>
                    Mobile: 8962635410{' '}
                  </Typography>{' '}
                  <Typography variant='h6' sx={{ margin: '15px' }}>
                    {' '}
                    Email:
                  </Typography>{' '}
                  <Typography variant='h8' sx={{ margin: '15px' }}>
                    contact @vedsiweb.com{' '}
                  </Typography>{' '}
                </Grid>{' '}
              </Grid>{' '}
            </Box>{' '}
            <Box
              sx={{
                // my: 2,
                '& svg': {
                  fontSize: '30px',
                  cursor: 'pointer',
                  mr: 2,
                  bgcolor: 'white',
                  borderRadius: '100%',
                },
                '& svg:hover': {
                  color: 'goldenrod',
                  transform: 'translateX(5px)',
                  transition: 'all 400ms',
                  textAlign: 'center',
                  borderRadius: '100%',
                },
              }}
            >
              {/* icon coding*/}
              <Link
                to='https://www.facebook.com/profile.php?id=61555498782019'
                target='_blank'
                rel='noreferrer'
              >
                <FacebookIcon />
              </Link>
              <Link
                to='https://www.linkedin.com/company/vedsi-it-solutions-private-limited/?viewAsMember=true'
                target='_blank'
                rel='noreferrer'
              >
                <LinkedInIcon />
              </Link>
              <Link
                to='https://twitter.com/VedsiItcompany'
                target='_blank'
                rel='noreferrer'
              >
                <TwitterIcon />
              </Link>
              {/* <YouTubeIcon />
              <InstagramIcon /> */}
            </Box>{' '}
          </Box>{' '}
          {/* extra Coding*/}{' '}
        </Container>{' '}
      </Box>{' '}
      <Box>
        <Grid
          sx={{
            textAlign: 'center',
            backgroundColor: ' #315b96',
            color: 'white',
            '@media (max-width:600px)': {
              fontSize: '0.8rem',
              textAlign: 'center',
              fontFamily: '"Roboto Condensed", Sans-serif',
            },
          }}
        >
          {' '}
          © 2024 Vedsi IT Solutions Pvt Ltd. | All Rights Reserved.{' '}
        </Grid>{' '}
      </Box>{' '}
    </div>
  )
}

export default Footer
