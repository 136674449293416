import React from 'react'
import Layout from '../components/Layout/Layout'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/ContactN.jpg'
import '../styles/HomeStyles.css'
import '../styles/Contact.css'
import Marquee from 'react-fast-marquee'

const ContactUs = () => {
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1 className='heading' data-aos='fade-right'>
          {' '}
          CONTACT US{' '}
        </h1>{' '}
        <br />{' '}
        <Marquee pauseOnHover speed={80} direction='right'>
          <div className='headerContainer1'>
            {' '}
            <h1> Keep In Touch With Us !!</h1>{' '}
          </div>{' '}
        </Marquee>{' '}
      </Container>{' '}
      <Box className='Card-bg'>
        <br />
        <Card
          data-aos='fade-left'
          data-aos-delay='200'
          sx={{
            maxWidth: 500,
            margin: '50px auto',
            padding: '20px 5px',
            boxShadow: 3,
          }}
        >
          <CardContent>
            <Typography variant='h5' gutterBottom>
              {' '}
              REQUEST A QUOTE{' '}
            </Typography>{' '}
            <Typography gutterBottom variant='body3' component='p'>
              Please fill up the Inquiry Form.We will contact you within 2
              working hours.{' '}
            </Typography>{' '}
            <br />
            <FormControl>
              <Grid container spacing={1}>
                <Grid xs={12} item>
                  <TextField
                    label='Contact Persons Name'
                    placeholder='Contact Persons Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='email'
                    label='Email'
                    placeholder='Enter email'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    type='number'
                    label='Contact Number'
                    placeholder='Enter phone number'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    label='Company Name'
                    placeholder='Company Name'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid xs={12} item>
                  <TextField
                    label='Your Inquiry'
                    multiline
                    rows={4}
                    placeholder='Type your message'
                    variant='outlined'
                    fullWidth
                    required
                  />
                </Grid>{' '}
                <Grid
                  xs={12}
                  item
                  sx={{ justifyContent: 'center', textAlign: 'center' }}
                >
                  <Button
                    type='submit' //variant='contained' fullWidth>
                    variant='contained'
                    size='large'
                  >
                    {' '}
                    Submit{' '}
                  </Button>{' '}
                </Grid>{' '}
              </Grid>{' '}
            </FormControl>{' '}
          </CardContent>{' '}
        </Card>{' '}
        <br />
      </Box>{' '}
      {/* <Box sx={{ width: '100%' }}> </Box>{' '} */} <br />
      <Box className='Contact'>
        <Container>
          <Grid
            container
            spacing={1}
            direction='column'
            data-aos='zoom-in'
            data-aos-delay='100'
            // sx={{
            //   '@media (max-width:600px)': {
            //     fontSize: '10px',
            //   },
            // }}
          >
            <Grid item container spacing={4} xs={12} sm={12} md={4} lg={3}>
              <Grid
                item
                xs={6}
                // sx={{
                //   '@media (max-width:600px)': {
                //     fontSize: '12px',
                //   },
                // }}
              >
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    // '@media (max-width:600px)': {
                    //   fontSize: '15px',
                    // },
                  }}
                >
                  {' '}
                  Register Office Address:{' '}
                </Typography>{' '}
                <p>
                  Vedsi IT Solutions Pvt Ltd, H.No - 39, Natural City,
                  Raipur(C.G.), PIN: 492001.{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={6}>
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    // '@media (max-width:600px)': {
                    //   fontSize: '15px',
                    // },
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                {/* <p> Land Line: 0771 - 2243022 </p>{' '} */}{' '}
                <p> Mobile: 7879957062 </p> <p>Mobile: 8962635410</p>
              </Grid>{' '}
              {/* <Grid item xs={3}>
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    '@media (max-width:600px)': {
                      fontSize: '15px',
                    },
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <p> contact@vedsiweb.com </p>{' '}
              </Grid>{' '} */}
            </Grid>{' '}
          </Grid>{' '}
          <br />
          <Grid
            container
            spacing={1}
            direction='column'
            data-aos='zoom-in'
            data-aos-delay='200'
          >
            <Grid item xs={12} container spacing={4}>
              <Grid item xs={6}>
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Head Office Address:{' '}
                </Typography>{' '}
                <p>
                  Vedsi IT Solutions Pvt Ltd, P.No - B-61/62, Vinayak City,
                  Bhatagaon, Raipur(C.G.), PIN: 492001.{' '}
                </p>{' '}
              </Grid>{' '}
              <Grid item xs={6}>
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {' '}
                  Contact Number:{' '}
                </Typography>{' '}
                <p> Mobile: 7879957062 </p> <p>Mobile: 8962635410</p>
              </Grid>{' '}
              {/* <Grid item xs={4}>
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: 'left',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                    '@media (max-width:600px)': {
                      fontSize: '16px',
                    },
                  }}
                >
                  {' '}
                  Email:{' '}
                </Typography>{' '}
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  // sx={{
                  //   '@media (max-width:600px)': {
                  //     fontSize: '8px',
                  //   },
                  // }}
                >
                  {' '}
                  contact@vedsiweb.com{' '}
                </Typography>{' '}
              </Grid>{' '} */}
            </Grid>{' '}
          </Grid>{' '}
        </Container>
      </Box>
      <br />
      <Box className='Goole-map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7438.905593572857!2d81.61569814103586!3d21.213886029873706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddede51f7f5f%3A0x5b9ab56d9b82605e!2sVedsi%20IT%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1705921094282!5m2!1sen!2sin'
          width='600'
          height='450'
          style={{ border: '0' }}
          allowfullscreen=''
          loading='lazy'
          referrerpolicy='no-referrer-when-downgrade'
        ></iframe>
      </Box>
    </Layout>
  )
}

export default ContactUs
