// import React from 'react'
import React, { useEffect } from 'react'
// import Accordion from '@mui/material/Accordion'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Layout from '../components/Layout/Layout'
// import Banner from '../Images/Pics/AboutN.jpeg'
// import Banner from '../Images/Vedsi Company Pics/About.jpg'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/AboutNew.jpg'
import { Container, Grid, Box } from '@mui/material'
import AboutInside from '../Images/Vedsi Company Pics/AboutInside.jpg'
import Mission from '../Images/Vedsi Company Pics/Mission.jpg'
import Vision from '../Images/Vedsi Company Pics/Vision.jpg'
import Values from '../Images/Vedsi Company Pics/Values.jpg'
// import Director from '../Images/Vedsi Company Pics/Director.jpg'

import ButtonBase from '@mui/material/ButtonBase'
// import { Link } from 'react-router-dom'

import '../styles/About.css'
import { styled } from '@mui/material/styles'
// import LinkedInIcon from '@mui/icons-material/LinkedIn'
// import FacebookIcon from '@mui/icons-material/Facebook'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

function About() {
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <Layout>
      <div className='home2' style={{ backgroundImage: `url(${Banner})` }}>
        {/* <div>
          <h1 className='Banner-heading' data-aos='fade-down'>
            {' '}
            About{' '}
          </h1>
        </div> */}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1 className='heading' data-aos='fade-right' data-aos-delay='100'>
          {' '}
          Who We Are ?{' '}
        </h1>{' '}
        <br />
        <p className='about-p ' data-aos='fade-left' data-aos-delay='200'>
          Vedsi IT Solutions is a software development and digital marketing
          company focused on using technology to enhance the business process.
          Vedsi has experienced and highly qualified dynamic professionals with
          great expertise to provide a world-class software development and
          digital transformation company from Raipur.
          <br />
          <br />
          We support our customers with innovative ideas through our deep
          technical expertise and agile development approach. We get engaged
          with customers right at the concept level and partner with them until
          the completion of their project. We fully focused on the key areas of
          web development, application development, product development, and
          application operations. We also provide digital solutions that solve
          complex problems and change businesses.
          <br />
          <br />
          We help our clients by providing innovative ideas with next-generation
          technology solutions and services and also by supporting their digital
          transformation journey. We always believe in consistently updating
          ourselves for faster technological evolution. We always focus on
          learning new innovative technologies in depth and applying them as per
          business requirements.
        </p>
        <br />
      </Container>
      <br />
      <br />
      <br />
      <br />
      <Box className='Beyond '>
        <Container>
          <Grid
            container
            spacing={12}
            direction='column'
            // sx={{ '@media only screen and (min-width: 768px)': ' padding:0px' }}
          >
            <Grid container item xs={12} spacing={8}>
              {' '}
              {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
              <Grid item>
                <ButtonBase
                  data-aos='fade-right'
                  sx={{
                    width: 500,
                    height: 400,
                    marginTop: '10px',
                    '@media (max-width:600px)': {
                      marginTop: '0px',
                      width: 345,
                      height: 325,
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={AboutInside}
                    sx={{
                      borderRadius: '15px',
                      marginTop: '80px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                  '@media (max-width:600px)': {
                    marginTop: '-20px',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                    '@media (max-width:600px)': {
                      marginTop: '-30px',
                    },
                  }}
                >
                  {' '}
                  Beyond The Imagination{' '}
                </Typography>{' '}
                <br />
                <p
                  className='About-accord '
                  data-aos='fade-left'
                  data-aos-delay='200'
                >
                  Vedsi provides excellent services in the field of software
                  operations, software applications, Web & Mobile Application
                  Development and Digital Marketing that enhance clients'
                  business operations. Our members helped successfully our
                  clients and save millions of dollars in software development
                  costs. Vedsi IT Solutions has been serving customers across
                  the globe.
                </p>
                {/* </p>{' '} */} <br />
                {/* <Link to='/about'>
                  {' '}
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    className='animation'
                  >
                    {' '}
                    Learn More{' '}
                  </Button>{' '}
                </Link>{' '} */}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
        </Container>{' '}
      </Box>
      <br />
      <br />
      <Box className=' Mission '>
        <Container>
          <Grid
            container
            spacing={12}
            direction='column'
            // sx={{ '@media only screen and (min-width: 768px)': ' padding:0px' }}
          >
            <Grid container item xs={12} spacing={8}>
              {' '}
              {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Our Mission{' '}
                </Typography>{' '}
                <br /> {/* <p className='Home-heading'> */}{' '}
                <p
                  className='About-accord '
                  data-aos='fade-left'
                  data-aos-delay='200'
                >
                  Vedsi provides the best Information Technology that
                  understands customer requirements and provides high-quality
                  products and services. To enhance and optimize the growth of
                  the business of clients. We are looking at client problems as
                  challenges, and we add value to your project with innovative
                  and cost-effective solutions to increase the client's company
                  efficiency.
                </p>
                {/* </p>{' '} */} <br />
                {/* <Link to='/about'>
                  {' '}
                  <Button
                    type='submit'
                    variant='contained'
                    color='success'
                    className='animation'
                  >
                    {' '}
                    Learn More{' '}
                  </Button>{' '}
                </Link>{' '} */}
              </Grid>{' '}
              <Grid item>
                <ButtonBase
                  data-aos='fade-right'
                  sx={{
                    width: 500,
                    height: 400,
                    marginTop: '40px',
                    '@media (max-width:600px)': {
                      width: 345,
                      height: 200,
                      marginTop: '-50px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={Mission}
                    sx={{
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
        </Container>{' '}
      </Box>
      <br />
      <br />
      <br />
      <Box className='Vision '>
        <Container>
          <Grid container spacing={12} direction='column'>
            <Grid container item xs={12} spacing={8}>
              {' '}
              {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                  marginTop: '-40px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  <br /> Our Vision{' '}
                </Typography>{' '}
                <br /> {/* <p className='Home-heading'> */}{' '}
                <p
                  className='About-accord '
                  data-aos='fade-up'
                  // data-aos-delay='200'
                >
                  The main objective of our vision is to help our clients exceed
                  their business results through software development, premier
                  Web Application Products, and Services. To achieve strategic
                  growth and excellence in the online business environment, we
                  are developing the latest and crystal clear solutions to help
                  our clients with business goals. We believe in accuracy and
                  top quality services. We want to be known as the reliable,
                  innovative and user-friendly software service provider in the
                  IT industry.
                </p>
                {/* </p>{' '} */} <br />
                {/* <Link to='/about'>
                  {' '}
                  <Button type='submit' variant='contained' color='success'>
                    {' '}
                    Learn More{' '}
                  </Button>{' '}
                </Link>{' '} */}
              </Grid>{' '}
              <Grid item>
                <ButtonBase
                  data-aos='fade-down'
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 365,
                      height: 200,
                      marginTop: '-50px',
                      marginRight: '60px',
                    },
                  }}
                >
                  <Img
                    className='animation'
                    alt='complex'
                    src={Vision}
                    sx={{
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          <br />
        </Container>{' '}
      </Box>
      <br />
      <br />
      <Box className='Values '>
        <Container>
          <Grid
            container
            spacing={12}
            direction='column'
            // sx={{ '@media only screen and (min-width: 768px)': ' padding:0px' }}
          >
            <Grid container item xs={12} spacing={8}>
              {' '}
              {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
              <Grid item>
                <ButtonBase
                  data-aos='zoom-in'
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 368,
                      height: 200,
                      // marginTop: '-10px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={Values}
                    sx={{
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                        // '& h4': {
                        //   fontSize: '1rem',
                        // },
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '2px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Our Core Values{' '}
                </Typography>{' '}
                <br /> {/* <p className='Home-heading'> */}{' '}
                <p
                  // className='About-accord '
                  sx={{ fontSize: '1.8rem' }}
                  data-aos='zoom-in'
                  data-aos-delay='100'
                >
                  <ul>
                    <li>
                      <b>Client-first:</b>
                      We prioritize our clients’ business and provide
                      cost-effective services and profits.
                    </li>
                    <li>
                      <b>Innovation:</b>Our team members are dedicated to
                      clients' business and doing integrated teamwork for new
                      innovating ideas as well.{' '}
                    </li>
                    <li>
                      <b>Integrity:</b>Our company commitment to integrity,
                      fairness and accountability are strengthened at all levels
                      too.
                    </li>
                    <li>
                      <b>Excellence:</b>We always believe in building to make
                      great efforts to achieve for excellence and taking
                      execution to a top level.
                    </li>
                    <li>
                      <b>Agility:</b> We always try to embrace agility, to
                      strive to act flexibly to achieve new technology for our
                      clients.
                    </li>
                  </ul>
                </p>
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
          {/* <br /> */}
        </Container>{' '}
      </Box>
      <br />
      <br />
      <br />
      <br />
      {/* <br />
      <br />
      <Container>
        <ul className='About-ul '>
          <li data-aos='fade-up '>
            Our origination’s function is run by proper channel and security
            service guide lines with an absolute command and control structure.
            Our field officers are well experienced in the art of discipline.
          </li>
          <li data-aos='fade-up ' data-aos-delay='400'>
            We have with us the trained and expert field officers, staffs and
            supervisors. Our officers regularly visit the sites to ensure better
            functioning and communication.
          </li>
          <li>
            We are ready to provide additional security personnel at very short
            notice to our regular and trusted clients.
          </li>
          <li>
            Our staff and security personnel are not member of any union. And
            are honest, loyal, disciplined, and presentable at all hours in all
            respects.
          </li>
          <li>
            The wages paid to our security person is according to the “Minimum
            Wages” as per government notification.
          </li>
          <li>In addition we provide the “DOG ESCORTS” as per requirement.</li>
          <li>
            We do not deploy any local person (Guards), we totally employ in our
            security, guards from other state.
          </li>
          <li>
            The contract will be accepted for minimum period of one year. If in
            the first work periods, our services are found as per satisfaction
            of the management, the contract may be renewed to the desired
            provider of management.
          </li>
        </ul>
        <br />
        <br />
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Company Profile
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                {' '}
                "MADHYA BHARAT SECURITY SERVICE is a professionally managed
                organization engaged as security services provider, and
                progressed role player within the security industry.MBSS is
                committed toward equal opportunity and affirmative action
                initiatives.We extend our services to Industrial security,
                educational institute, Commercial Complex, Mines, Hospital and
                residential complex.We deploy trained Security personnel."{' '}
              </p>{' '}
              <br />
              <p className='About-accord'>
                "Our team is consisting of well trained, professional and good
                communicated persons.Madhya Bharat Security Service is a
                registered agency under the shop and establishment, Labor
                department State Government of Chhattisgarh."{' '}
              </p>{' '}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Vision
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We believe that perfect security is a target that can be
                achieved through trust and team work and field monitoring all of
                which are elements of professionalism. Your safety and peace of
                mind is the main aim which we strive to secure at all times
                through a strategy focused on trust, professionalism &
                integrated services."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Mission
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We are the source of inspiration and delight to our customers
                by providing committed and motivated workforce with value
                addition to our service by a system of continuous feedback and
                analysis."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Our Environment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We offer a healthy work environment, which starts at training
                in which professionals knowledge is imparted to ensure pride in
                job and better understanding of situations that are encountered
                in this field. The policy of human welfare is followed within
                the framework of laws of the land."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                House Keeping
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "Our trained personnel for maintenance of offices, corporate
                clients, hospitals and banking sectors are available for
                providing housekeeping support."
              </p>
              <p className='About-accord'>
                Our team is motivated to perform under all circumstances and we
                are looking forward to provide you the best of services with
                emphasis on training followed by customized services based on
                system of feedback and analysis by personal interactions.
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Company License Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul className='About-licence'>
                <li>Company Registration No. : 005228/RPR/S/2016</li>
                <li>
                  Company Labour Licence Registration No. : RPR/2023/44032565
                </li>
                <li>Company MSME Registration No. : UDYAM-CG-14-0030182 </li>
                <li>Company ISO 9001:2015 Registration No. : 305022111008Q</li>
                <li>Company Pan Registration No. : BIWPP2036G</li>
                <li>
                  Company Provident Fund Registration No. : CGRAI1557472000
                </li>
                <li>
                  Company ESIC Registration No.(Chhattisgarh) :
                  59001575650001099{' '}
                </li>
                <li>
                  Company ESIC Registration No.(Odisha) : 44591575650011099{' '}
                </li>
                <li>Company Pasara Registration No. : (04/17)/734/2022</li>
                <li>Company GST Registration No. : 22BIWPP2036G1Z6 </li>
                <li>Labour Welfare Registration No. : 27802 </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                General Manager / Operation Manager / Area Manager
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "We can always locate area manager / supervisor close to our
                clients premises, so that daily liaison is established for
                effective command and control overall security. Our managers /
                supervisors organize monitor of the system and take necessary
                action immediately, if required."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Recruitment
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "To keep our standards and promise to provide the best
                professional security service, we recruit the best candidates.
                We give utmost attention to the recruitment process which
                follows our stringent corporate hiring rules. Each candidate has
                to undergo a rigorous physical fitness examination to gauge his
                tolerance, his ability to cope with difficult situations and his
                agility to act during emergencies. Also, their background is
                verified with the help of police. That’s not all, a candidate is
                only appointed when he is referred by a reliable source."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Training
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "After appointment all the candidates undergo a rigorous
                training for a month by highly trained professionals who have
                retired from top posts in military, police and fire service
                departments. They are given theoretical and practical lesions on
                basic guarding skills, rudiments on first aid, firefighting and
                self-defense. They are also given classes on courtesy,
                obedience, mannerism, fire prevention, disaster management and
                human psychology, to name a few."
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography
                sx={{
                  color: 'red',
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              >
                Supervision
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p className='About-accord'>
                "Our security personnel are supervised by area officers and area
                managers who frequent during day and night for surprise checks
                to ensure that everything is going smooth."
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container> */}
    </Layout>
  )
}

export default About
