// import React from 'react'
import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import { Button, Container, Typography, Box } from '@mui/material'
import { Link } from 'react-router-dom'

// import Banner from '../Images/Vedsi Company Pics/Home.jpg'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/HomeN.jpg'
import '../styles/HomeStyles.css'
// import Data from './DataH.json'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { CardActionArea, Grid, CardActions } from '@mui/material'
import Marquee from 'react-fast-marquee'
import ButtonBase from '@mui/material/ButtonBase'
import SendIcon from '@mui/icons-material/Send'
import { styled } from '@mui/material/styles'

// Services
import WebDevelopment from '../Images/Vedsi Company Pics/Services/WebDevelopment.jpg'
import SoftwareDevelopement from '../Images/Vedsi Company Pics/Services/SoftwareDevelopement.png'
import DigitalMark from '../Images/Vedsi Company Pics/Services/DigitalMarketing.jpg'
import MobileAppication from '../Images/Vedsi Company Pics/Services/MobileAppication.jpg'
import Ecommerce from '../Images/Vedsi Company Pics/Services/Ecommerce.jpg'
import SEO from '../Images/Vedsi Company Pics/Services/SEO.jpg'
import FacebookMarketing from '../Images/Vedsi Company Pics/Services/FacebookMarketing.jpg'
import ConsultingServices from '../Images/Vedsi Company Pics/Services/ConsultingServices.png'
import AI from '../Images/Vedsi Company Pics/Services/AI.jpg'
import Payroll from '../Images/Vedsi Company Pics/Services/Payroll.jpg'

// import Card1 from '../Images/Vedsi Company Pics/Card1.jpg'
// import Card2 from '../Images/Vedsi Company Pics/Card2.jpg'
// import Card3 from '../Images/Vedsi Company Pics/Card3.jpg'
// import Card4 from '../Images/Vedsi Company Pics/Card4.jpg'
// import Card5 from '../Images/Vedsi Company Pics/Card5.jpg'
// import Card6 from '../Images/Vedsi Company Pics/Card6.jpg'
import Card7 from '../Images/Vedsi Company Pics/Card7.jpg'

// Developer Partnership

import MBBS from '../Images/Vedsi Company Pics/MBSS.jpg'
import img1 from '../Images/Clients/NC.png'
import img2 from '../Images/Clients/Gaurav Electricals.png'
import img3 from '../Images/Clients/Venika Hydro Projects Pvt Ltd..png'
import img4 from '../Images/Clients/APL APOLLO TUBES LTD..jfif'
import img5 from '../Images/Clients/NTPC.png'
import img6 from '../Images/Clients/Bajaj Township.png'
import img7 from '../Images/Clients/R K Transport & Construction Ltd..png'
import img8 from '../Images/Clients/Shaksham Food Industries.png'
import img9 from '../Images/Clients/U B Ventures Pvt Ltd..png'
import img10 from '../Images/Clients/VGR Real Estate (Metro Green’s).png'

import DigitalMarketing from '../Images/Vedsi Company Pics/Industries Served/DigitalMarketing.jpg'
import Automobile from '../Images/Vedsi Company Pics/Industries Served/Automobile.jpg'
import Technology from '../Images/Vedsi Company Pics/Industries Served/Technology.jpg'
import Education from '../Images/Vedsi Company Pics/Industries Served/Education.jpg'
import HealthCare from '../Images/Vedsi Company Pics/Industries Served/HealthCare.jpg'
import Finance from '../Images/Vedsi Company Pics/Industries Served/Finance.jpg'
import Manufacturing from '../Images/Vedsi Company Pics/Industries Served/Manufacturing.jpg'
import RealTrade from '../Images/Vedsi Company Pics/Industries Served/RealTrade.jpg'
import RealEstate from '../Images/Vedsi Company Pics/Industries Served/RealEstate.jpg'

// Technology

import Angular from '../Images/Vedsi Company Pics/Technologies/Angular.png'
import Html from '../Images/Vedsi Company Pics/Technologies/Html.png'
import Java from '../Images/Vedsi Company Pics/Technologies/Java.png'
import MySQL from '../Images/Vedsi Company Pics/Technologies/MySQL.png'
import NET from '../Images/Vedsi Company Pics/Technologies/NET.png'
import NETCore from '../Images/Vedsi Company Pics/Technologies/NETCore.png'
import NodeJs from '../Images/Vedsi Company Pics/Technologies/NodeJs.png'
import Python from '../Images/Vedsi Company Pics/Technologies/Python.png'
import ReactJs from '../Images/Vedsi Company Pics/Technologies/React.png'
import SQLServer from '../Images/Vedsi Company Pics/Technologies/SQLServerLogo.png'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const Home = () => {
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <Layout>
      <div className='home' style={{ backgroundImage: `url(${Banner})` }}>
        <div className='headerContainer'>
          <h1 data-aos='fade-down'> Digital Revolution </h1>{' '}
          <Marquee pauseOnHover speed={70} width='10%' direction='right'>
            <p> Enhancing Digital Revolution </p>{' '}
          </Marquee>{' '}
          <Link to='/ContactUs'>
            {' '}
            <Button type='submit' variant='contained' data-aos='fade-up'>
              {' '}
              ENQUIRE NOW !{' '}
            </Button>{' '}
          </Link>{' '}
        </div>{' '}
      </div>{' '}
      <Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
            },
          },
        }}
      >
        <Marquee pauseOnHover speed={80}>
          <div className='headerContainer1'>
            {' '}
            <h2> Welcome to Vedsi IT Solutions</h2>{' '}
          </div>{' '}
        </Marquee>{' '}
      </Container>{' '}
      <br />
      <Container>
        <Grid
          container
          spacing={12}
          direction='column'
          // sx={{ '@media only screen and (min-width: 768px)': ' padding:0px' }}
        >
          <Grid container item xs={12} spacing={8}>
            {' '}
            {/* <Grid item xs={6} sm={6}> */} {/* </Grid>{' '} */}{' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='fade-right'
              data-aos-delay='200'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Libre Baskerville", Sans-serif',
                  fontSize: '3.5rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: '600',
                  letterSpacing: '0px',
                  color: '#313131',
                  '@media (max-width:600px)': {
                    mt: 0,
                    fontSize: '2.5rem',
                    fontWeight: '500',
                  },
                }}
              >
                {' '}
                What is Vedsi ?{' '}
              </Typography>{' '}
              <br />
              We start life in 2021 as a small, self-funded team of technology
              specialists. We're a software development company focused on using
              new technology.
              <br />
              <br />
              <b>Innovative tech solutions for client business</b>
              <br />
              We're now a leading digital consulting company, providing
              innovative technology solutions for client business. As a trusted
              partner, we're always at the forefront of change as we lead
              digital optimization and modernization journeys for our clients.
              <br />
              <br />
              <b>Customized end-to-end solutions</b>
              <br /> Our expertise in Artificial Intelligence, Consulting, Data,
              Digital, Software Engineering, delivers customized, end-to-end
              solutions that drive business value and growth.
              <br />
              <br />
              <Link to='/about'>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='success'
                  data-aos='fade-left'
                  data-aos-delay='100'
                >
                  {' '}
                  Read More{' '}
                </Button>{' '}
              </Link>{' '}
            </Grid>{' '}
            <Grid item>
              <ButtonBase
                data-aos='fade-right '
                data-aos-delay='200'
                sx={{
                  width: 500,
                  height: 400,
                  marginTop: '120px',
                  '@media (max-width:600px)': {
                    marginTop: '0px',
                    width: 340,
                    height: 250,
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={Card7}
                  sx={{
                    marginTop: '0px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                      // '& h4': {
                      //   fontSize: '1rem',
                      // },
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Grid>{' '}
      </Container>
      <br />
      <br />
      <Box className='Grid-background'>
        <Container
          // className='animation'
          sx={{
            my: 0,
            textAlign: 'center',
            p: 2,
            '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
            '& p': { textAlign: 'justify' },
            '@media (max-width:600px)': {
              mt: 0,
              '& h4': {
                fontSize: '1rem',
              },
            },
          }}
        >
          <h1 className='heading' data-aos='fade-left ' data-aos-delay='200'>
            {' '}
            Our Services{' '}
          </h1>{' '}
          {/* <br /> */}
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '20px',
              className: 'CardPic',
              marginLeft: '5px',
              textAlign: 'center',
              '@media (max-width:600px)': {
                mt: 0,
                '& h4': {
                  fontSize: '0.7rem',
                  width: '100%',
                  marginLeft: 5,
                  paddingLeft: 7,
                  marginTop: '10px',
                },
              },
            }}
          >
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    mr: '15px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={WebDevelopment}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Services' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Website Development{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Typography variant='body2' color='black'>
                      {' '}
                    </Typography>{' '} */}
                  </CardContent>{' '}
                </CardActionArea>{' '}
                <CardActions>
                  {/* <Link to='/Services' size='small'>
                    {' '}
                    <Button variant='contained' size='medium' color='success'>
                      Know More{' '}
                    </Button>
                  </Link>{' '} */}
                  {/* <Link to='/Services' size='small'>
                  {' '}
                  Know More{' '}
                </Link>{' '} */}
                </CardActions>
              </Card>{' '}
            </Grid>{' '}
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='200'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={SoftwareDevelopement}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Software Development
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                    {/* <Link to='/Services' size='small'>
                    {' '}
                    Know More{' '}
                  </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='300'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={DigitalMark}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Digital Marketing
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <br />
            <br />
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-left'
              data-aos-delay='100'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={MobileAppication}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Mobile Application
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-left'
              data-aos-delay='200'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={Ecommerce}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          E-commerce Website
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-left'
              data-aos-delay='300'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={SEO}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Search Engine Optimization
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='100'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={FacebookMarketing}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Facebook Marketing
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='200'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={ConsultingServices}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Consulting Services
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-right'
              data-aos-delay='300'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={AI}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          AI & Machine Learning
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='fade-left'
              data-aos-delay='300'
            >
              <Grid item>
                <Card
                  sx={{
                    maxWidth: 345,
                    marginTop: '30px',
                    '@media (max-width:600px)': {
                      mr: '15px',
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component='img'
                      height='250'
                      image={Payroll}
                      alt='Gaurd'
                    />
                    <CardContent>
                      <Link to='/Services' size='small'>
                        <Typography gutterBottom variant='h5' component='div'>
                          Payroll Outsourcing
                        </Typography>{' '}
                      </Link>
                      {/* <Typography variant='body2' color='black'>
                        {' '}
                      </Typography>{' '} */}
                    </CardContent>{' '}
                  </CardActionArea>{' '}
                  <CardActions>
                    {/* <Link to='/Services' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Know More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardActions>
                </Card>{' '}
              </Grid>{' '}
            </Grid>
          </Grid>
          {/* Card data is used */}
          {/* <Grid
          container
          spacing={5}
          style={{ marginTop: '20px' }}
          className='CardPic'
        >
          {' '}
          {Data.map((result, index) => (
            <Grid item xs={12} md={4} sm={4} key={index}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='350'
                    image={result.img}
                    alt='green iguana'
                  />{' '}
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      {result.title}{' '}
                    </Typography>{' '}
                    <Typography variant='body2' color='text.secondary'>
                      {' '}
                      {result.description}{' '}
                    </Typography>{' '}
                  </CardContent>{' '}
                </CardActionArea>{' '}
                <CardActions>
                  {' '}
                  {/* <Button size='small'> Share </Button>{' '} */}{' '}
          {/* <Link to='/SecurityServices' size='small'>
          {' '}
          Learn More{' '}
        </Link>{' '} */}
          {/* </CardActions>{' '}
              </Card>{' '}
            </Grid>
          ))}{' '}
        </Grid>{' '} */}{' '}
          {/* */}
          <br />
          <br />
        </Container>
        <br />
        <br />
      </Box>
      <Box>
        <Container className='Project-background'>
          <Grid container item xs={12} spacing={0}>
            <Grid
              item
              xs={8}
              sm={12}
              data-aos='fade-right'
              data-aos-delay='200'
              sx={{
                fontSize: '3rem',
                '@media (max-width:600px)': {
                  //    fontFamily: '600',
                  fontSize: '1.5rem',
                  //  textAlign: 'center',
                },
              }}
            >
              Feel free to discuss your project?
            </Grid>
            <br />
            <br />
            <Grid item xs={4} sm={12} data-aos='fade-left' data-aos-delay='200'>
              <Link to='/contactus'>
                {' '}
                <Button
                  variant='contained'
                  endIcon={<SendIcon />}
                  sx={{
                    '@media only screen and (max-width:600px)': {
                      fontSize: '0.7rem',
                      // marginTop: '10px',
                      // textAlign: 'center',
                      // justifyContent: 'center',
                    },
                  }}
                >
                  CONTACT US
                </Button>
              </Link>{' '}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <br /> <br />
      <Box className='Client-background'>
        <Container
          sx={{
            my: 0,
            textAlign: 'center',
            p: 2,
            '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
            '& p': { textAlign: 'justify' },
            '@media (max-width:600px)': {
              mt: 0,
              '& h4': {
                fontSize: '1rem',
              },
            },
          }}
        >
          <h1 className='heading'> Our Developer Partnership </h1> <br /> <br />
          <div>
            <Marquee pauseOnHover speed={100}>
              <Link to='/developerpartnership' size='small'>
                {' '}
                <div className='image_wrapper'>
                  <img src={MBBS} alt='' />
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                {' '}
                <div className='image_wrapper'>
                  <img src={img1} alt='' />
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                {' '}
                <div className='image_wrapper'>
                  <img src={img2} alt='' />
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img5} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img4} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img3} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img6} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img7} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img8} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img9} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
              <Link to='/developerpartnership' size='small'>
                <div className='image_wrapper'>
                  <img src={img10} alt='' />{' '}
                </div>{' '}
              </Link>{' '}
            </Marquee>{' '}
          </div>{' '}
        </Container>
      </Box>
      <Box
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
            },
          },
        }}
      >
        <h1 className='heading'> Industries Served </h1>
        <br />
      </Box>
      <Box>
        <Container>
          <Grid
            container
            spacing={2}
            style={{
              // marginTop: '60px',
              // className: 'Industry-card',
              marginLeft: '5px',
              textAlign: 'center',
              hover: '10px',
              '@media (max-width:600px)': {
                mt: 0,
                '& h4': {
                  fontSize: '0.7rem',
                  width: '100%',
                  marginLeft: 5,
                  paddingLeft: 7,
                  marginTop: '10px',
                },
              },
            }}
          >
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='zoom-in '
                    component='img'
                    height='250'
                    image={DigitalMarketing}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Digital Marketing{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Automobile}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Automobile{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Technology}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Technology{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Education}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Education{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Finance}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Finance{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={HealthCare}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Health Care{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={Manufacturing}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Manufacturing{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={RealTrade}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Real Trade{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='Industry-card'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 345,
                  marginTop: '30px',
                  '@media (max-width:600px)': {
                    ml: '-17px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component='img'
                    height='250'
                    image={RealEstate}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Link to='/Industries' size='small'>
                      <Typography gutterBottom variant='h5' component='div'>
                        {' '}
                        Real Estate{' '}
                      </Typography>{' '}
                    </Link>
                    {/* <Link to='/Industries' size='small'>
                      {' '}
                      <Button variant='contained' size='medium' color='success'>
                        Read More{' '}
                      </Button>
                    </Link>{' '} */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <br />
        <br />
      </Box>
      <Box
        className='Grid-background'
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
            },
          },
        }}
      >
        <h1 className='heading'> Our Expert Technologies </h1> <br />
        <Container>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '20px',
              className: 'CardPic',
              marginLeft: '5px',
              textAlign: 'center',
              '@media (max-width:600px)': {
                mt: 0,
                '& h4': {
                  fontSize: '0.7rem',
                  width: '100%',
                  marginLeft: 5,
                  paddingLeft: 7,
                  marginTop: '10px',
                },
              },
            }}
          >
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                  // marginTop: '10px',
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={Angular}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Angular{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  '@media (max-width:600px)': {
                    ml: '50px',
                    marginTop: '20px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={Html}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Html{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  '@media (max-width:600px)': {
                    ml: '50px',
                    marginTop: '20px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={Java}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Java{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={MySQL}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      MySQL{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={NET}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      .NET{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={NETCore}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      .NET Core{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={NodeJs}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Node Js{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='200'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={Python}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      Python{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='300'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='flip-right '
                    component='img'
                    height='100'
                    image={ReactJs}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      React{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid
              xs={12}
              md={4}
              sm={4}
              className='CardPic'
              data-aos='zoom-in'
              data-aos-delay='100'
            >
              <Card
                sx={{
                  maxWidth: 200,
                  marginTop: '20px',
                  '@media (max-width:600px)': {
                    ml: '50px',
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    data-aos='fade-right '
                    component='img'
                    height='100'
                    image={SQLServer}
                    alt='Gaurd'
                  />
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                      {' '}
                      SQL Server{' '}
                    </Typography>{' '}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default Home
