import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Grid,

  // Button,
} from '@mui/material'
import { Link } from 'react-router-dom'

import { NavLink } from 'react-router-dom'
import '../../styles/HeaderStyles.css'
import MenuIcon from '@mui/icons-material/Menu'
// import Logo from '../../Images/New Photos/Logo.svg.jpg'
// import Logo from '../../Images/New Photos/Logo.svg.jpg'
import Logo from '../../Images/Vedsi Company Pics/VedsiLogo.jpeg'

import ContactMailIcon from '@mui/icons-material/ContactMail'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'

import Aos from 'aos'
import 'aos/dist/aos.css'

function Header() {
  const [mobileOpen, setMobileOpen] = useState(false)

  // handle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  //menu drawer
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', color: 'black' }}
    >
      {' '}
      <Typography
        //color={'goldenrod'}
        color={'#2962ff'}
        variant='h4'
        component='div'
        sx={{ flexGrow: 1, my: 2, fontSize: '1.2rem' }}
      >
        {/* <SecurityIcon sx={{ mr: 1 }} />{' '} */}{' '}
        <div>
          {' '}
          <img className='logo' src={Logo} alt='logo' />
        </div>
        Vedsi IT Solutions Pvt Ltd{' '}
      </Typography>{' '}
      <Divider />
      <ul className='mobile-navigation'>
        <li>
          <NavLink activeClassName='active' to={'/'}>
            {' '}
            Home{' '}
          </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/about'}> About Us </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/services'}> Services </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/products'}> Products </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/developerpartnership'}>
            {' '}
            Developer Partnership{' '}
          </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/industries'}> Industries </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/career'}> Career </NavLink>{' '}
        </li>{' '}
        <li>
          <NavLink to={'/contactus'}> Contact Us </NavLink>{' '}
        </li>{' '}
      </ul>{' '}
    </Box>
  )
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <>
      <Box>
        <AppBar
          className='Appbar'
          component={'nav'}
          sx={{
            // bgcolor: 'iniherit',
            bgcolor: 'white',
            // bgcolor: 'light blue',
            top: '0',
            '@media (max-width:600px)': { top: '0' },
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              '@media (max-width:600px)': { display: 'none' },
            }}
            // sx={{
            //   flexGrow: 1,
            //   '@media (max-width:600px)': {
            //     display: 'flex',
            //     width: '100%',
            //   },
            // }}
          >
            <Grid
              container
              spacing={0}
              columns={16}
              sx={{
                // backgroundColor: '#083c5a',
                backgroundColor: ' #315b96',
              }}
            >
              <Box
                sx={{
                  marginLeft: '30px',
                  textAlign: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                  bgcolor: 'white',
                  borderRadius: '15%',
                }}
              >
                <Link
                  to='https://www.facebook.com/profile.php?id=61555498782019'
                  target='_blank'
                  rel='noreferrer'
                >
                  <FacebookIcon />
                </Link>
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '20px',
                  textAlign: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                  bgcolor: 'white',
                  borderRadius: '15%',
                }}
              >
                <Link
                  to='https://www.linkedin.com/company/vedsi-it-solutions-private-limited/?viewAsMember=true'
                  target='_blank'
                  rel='noreferrer'
                >
                  <LinkedInIcon />
                </Link>
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '20px',
                  textAlign: 'center',
                  marginTop: '5px',
                  marginBottom: '5px',
                  bgcolor: 'white',
                  borderRadius: '15%',
                }}
              >
                <Link
                  to='https://twitter.com/VedsiItcompany'
                  target='_blank'
                  rel='noreferrer'
                >
                  <TwitterIcon />
                </Link>
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '25em',
                  fontSize: '1rem',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                An ISO 9001: 2015 Certified Company{' '}
              </Box>{' '}
              {/* *******************mail address********************* */}{' '}
              <Box
                sx={{
                  marginLeft: '15em',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                <ContactPhoneIcon />
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '20px',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {' '}
                (+91) 7879957062{' '}
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '10em',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                <ContactMailIcon />
              </Box>{' '}
              <Box
                sx={{
                  marginLeft: '20px',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                {' '}
                contact @vedsi.com{' '}
              </Box>{' '}
            </Grid>{' '}
          </Box>{' '}
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              // color='inherit'
              color='black'
              //aria-label='open drawer'

              sx={{ mr: 0, display: { sm: 'none' } }}
              onClick={handleDrawerToggle}
            >
              {' '}
              <MenuIcon />{' '}
            </IconButton>{' '}
            <div>
              <img className='logo' src={Logo} alt='logo' />
            </div>{' '}
            <Typography
              //color={'goldenrod'}
              color={'black'}
              variant='h5'
              component='div'
              sx={{
                flexGrow: 1,
                my: 2,
                fontSize: '1.7em',
                fontWeight: '600',
                fontFamily: 'Libre Baskerville ,serif',
                '@media (max-width:600px)': {
                  fontSize: '1.1em',
                },
              }}
            >
              Vedsi IT Solutions Pvt Ltd{' '}
            </Typography>{' '}
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <ul className='navigation-menu' data-aos='fade-up'>
                <li>
                  <NavLink activeClassName='active' to={'/'}>
                    {' '}
                    HOME{' '}
                  </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/about'}> ABOUT US </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/services'}> SERVICES </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/products'}> PRODUCTS </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/developerpartnership'}>
                    {' '}
                    DEVELOPER PARTNERSHIP{' '}
                  </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/industries'}> INDUSTRIES</NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/career'}> CAREER </NavLink>{' '}
                </li>{' '}
                <li>
                  <NavLink to={'/contactus'}> CONTACT US </NavLink>{' '}
                </li>{' '}
              </ul>{' '}
            </Box>{' '}
            {/* <Link to='/contactus'>
              <Button
                variant='contained'
                color='error'
                data-aos='fade-up'
                sx={{
                  '@media (max-width:600px)': { display: 'none' },
                }}
              >
                {' '}
                GET FREE AUDIT{' '}
              </Button>{' '}
            </Link>{' '} */}
          </Toolbar>{' '}
        </AppBar>{' '}
        <Box component={'nav'}>
          <Drawer
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: '240px',
              },
            }}
          >
            {' '}
            {drawer}{' '}
          </Drawer>{' '}
        </Box>{' '}
        {/* </Box><Box sx={{ p: 3 }}> */}{' '}
        <Box>
          <Toolbar />{' '}
        </Box>{' '}
      </Box>{' '}
    </>
  )
}

export default Header
