import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
// import Card from '@mui/material/Card'
// import CardContent from '@mui/material/CardContent'
// import CardMedia from '@mui/material/CardMedia'
// import Typography from '@mui/material/Typography'
import {
  Box,
  ButtonBase,
  Container,
  Grid,
  Typography,
  // Grid,

  // CardActions,
  // CardActionArea,
  // Button,
} from '@mui/material'
// import Banner from '../Images/Pics/SecurityServices.jpeg'
import Banner from '../Images/Vedsi Company Pics/Nav Pages/ServiceN.jpg'
// import Data from './Data.json'
// import { Link } from 'react-router-dom'
import '../styles/Services.css'

// Services
import WebDevelopment from '../Images/Vedsi Company Pics/Services/WebDevelopment.jpg'
import SoftwareDevelopement from '../Images/Vedsi Company Pics/Services/SoftwareDevelopement.png'
import DigitalMark from '../Images/Vedsi Company Pics/Services/DigitalMarketing.jpg'
import MobileAppication from '../Images/Vedsi Company Pics/Services/MobileAppication.jpg'
import Ecommerce from '../Images/Vedsi Company Pics/Services/EcommerceN.png'
import SEO from '../Images/Vedsi Company Pics/Services/SEO.jpg'
import FacebookMarketing from '../Images/Vedsi Company Pics/Services/FacebookMarketing.jpg'
import ConsultingServices from '../Images/Vedsi Company Pics/Services/ConsultingServices.png'
import AI from '../Images/Vedsi Company Pics/Services/AI.jpg'
import Payroll from '../Images/Vedsi Company Pics/Services/Payroll.jpg'

// import Card1 from '../Images/Vedsi Company Pics/Card1.jpg'
// import Card2 from '../Images/Vedsi Company Pics/Card2.jpg'
// import Card3 from '../Images/Vedsi Company Pics/Card3.jpg'
// import Card4 from '../Images/Vedsi Company Pics/Card4.jpg'
// import Card5 from '../Images/Vedsi Company Pics/Card5.jpg'
// import Card6 from '../Images/Vedsi Company Pics/Card6.jpg'
// import Card7 from '../Images/Vedsi Company Pics/Card7.jpg'
// import Card8 from '../Images/Vedsi Company Pics/Card8.jpg'
// import Card9 from '../Images/Vedsi Company Pics/Card9.jpg'

import { styled } from '@mui/material/styles'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const Services = () => {
  useEffect(() => {
    Aos.init({
      // offset: 100,
      duration: 1000,
      easing: 'ease-in-sine',
      // delay: 100,
    })
  }, [])
  return (
    <Layout>
      <div className='home1' style={{ backgroundImage: `url(${Banner})` }}>
        {/* <div>
          <h1 className='Banner-heading' data-aos='fade-down'>
            {' '}
            Services{' '}
          </h1>
        </div>{' '} */}
      </div>{' '}
      <Container
        Container
        sx={{
          my: 0,
          textAlign: 'center',
          p: 2,
          '& h4': { fontWeight: 'bold', my: 2, fontSize: '2rem' },
          '& p': { textAlign: 'justify' },
          '@media (max-width:600px)': {
            mt: 0,
            '& h4': {
              fontSize: '1rem',
              my: 0,
            },
          },
        }}
      >
        <h1 className='heading' data-aos='fade-right'>
          {' '}
          OUR SERVICES{' '}
        </h1>{' '}
        <br />
        <p className='Paragraph' data-aos='fade-left' data-aos-delay='200'>
          {' '}
          Vedsi provides software development and digital marketing services in
          Raipur, which focus on creating exclusive websites for all types of
          industries, especially the IT field and healthcare services. Website
          design and development is the art of playing with ideas and technical
          aspects and delivering the best to clients and customers. In the past
          several years, many innovations have propelled the IT field and
          healthcare services. We monitor website development using the design
          flow method. The development phase is carried out by converting static
          phases into custom-developed websites through customized designs. We
          provide the best services in Raipur at a very impressive cost. We are
          also creating a simple and attractive web design and development for
          an e-commerce store for your business as well.
        </p>{' '}
      </Container>{' '}
      {/* card material ui */}{' '}
      <Box className='Service-bg'>
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid item data-aos='fade-right'>
                <ButtonBase
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 345,
                      height: 200,
                      marginTop: '40px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={WebDevelopment}
                    sx={{
                      // marginTop: '20px',
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='fade-left'
                data-aos-delay='200'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Website Development{' '}
                </Typography>{' '}
                <br />
                <p className='About-accord '>
                  Website development is a broad technology that refers to the
                  tasks associated with creating, building, and maintaining
                  websites and web applications that run online in a browser. It
                  includes web design, web programming, and database management.
                  We use technologies that are totally focused on client
                  requirements. Our software engineers and developers are
                  experts at creating websites, from static design ideas to
                  moving websites. We always believe in focusing on creating an
                  impact through attracting websites.
                </p>{' '}
                <br />
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <Box>
        <Container>
          <Grid container item xs={12} spacing={8}>
            {' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='zoom-in'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Software Development{' '}
              </Typography>{' '}
              <br />
              <p className='About-accord '>
                In the present scenario, to automate the work and get all the
                results on one platform in the minimum time duration. Software
                is a tool that helps enhance your business and provides all the
                details that can be utilized for creating, designing, deploying,
                supporting, and giving benefits to the organization. We provide
                advanced software and give services, products, and services a
                customized level of development, from small software to ERP
                solutions. We provide the latest software development
                technologies to build business-centric web applications.
              </p>{' '}
            </Grid>{' '}
            <Grid item data-aos='zoom-in' data-aos-delay='200'>
              <ButtonBase
                sx={{
                  width: 500,
                  height: 500,
                  marginTop: '-30px',
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 200,
                    marginTop: '-40px',
                    marginBottom: '-5px',
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={SoftwareDevelopement}
                  sx={{
                    // marginTop: '20px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <br />
      <br />
      <br /> <br />
      <Box className='Service-bg'>
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid item data-aos='fade-right'>
                <ButtonBase
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 368,
                      height: 200,
                      marginTop: '50px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={DigitalMark}
                    sx={{
                      // marginTop: '20px',
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='fade-left'
                data-aos-delay='200'
                // data - aos = 'fade-left'
                // data - aos - delay = '200'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Digital Marketing{' '}
                </Typography>{' '}
                <br />
                <p className='About-accord '>
                  Vedsi is a leading digital marketing agency in Raipur. Enhance
                  your business goals with ideas and specific digital
                  strategies. We are developing a result-oriented digital
                  marketing solution with a conceptualization approach for
                  various businesses on the digital platform. Our expert team
                  members will plan appropriate digital marketing strategies for
                  start-ups as well as experienced organizations as well as
                  support them in a better environment. Some services are as
                  follows:
                </p>{' '}
                <p sx={{ fontSize: '1.5em' }}>
                  <ul>
                    <li>
                      <b> Social Media Marketing: </b>
                      Social media plays an important role in digital marketing
                      services for business branding and promotion. We can help
                      you to build your brand image with innovative ideas and
                      effective content.{' '}
                    </li>{' '}
                    <li>
                      <b> Search Engine Optimization: </b>
                      Our SEO expert team members will enhance your business
                      portal's visibility in the portal and rank up in the
                      competitive environment in search terms. We also assist
                      you in getting your business to the top of search engine
                      results.
                    </li>{' '}
                    <li>
                      <b> Content Marketing: </b>
                      In the current trends, sharing information is a valuable
                      approach to digital marketing. Our team members are
                      experts at creating and delivering appropriate content
                      with innovative ideas and strategies that are related to
                      your business and can help you build and gain good
                      customers in the market.
                    </li>{' '}
                    <li>
                      <b> Email Marketing: </b>
                      Today, email marketing is a precious tool and technique to
                      convey information to existing customers and maintain the
                      relationships so that we can connect through email
                      marketing services, which is a part of promotional
                      Strategies that help to improve business.
                    </li>{' '}
                  </ul>{' '}
                </p>{' '}
                <br />
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <Box>
        <Container>
          <Grid container item xs={12} spacing={8}>
            {' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='zoom-in-right'
              data-aos-delay='200'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Mobile Application{' '}
              </Typography>{' '}
              <br />
              <p className='About-accord '>
                In the present scenario, everyone has a smart phone, and here
                comes the mobile applications to run on various mobile
                platforms. We have expert members who create or make mobile
                application with good features and parameters with flexible
                services for your business. We are working on native apps and
                web apps and hybrid apps.{' '}
              </p>{' '}
              <p sx={{ fontSize: '1.5em' }}>
                <ul>
                  <li>
                    <b> Native apps: </b>
                    Native apps are created for one specific platform or
                    operating system.{' '}
                  </li>{' '}
                  <li>
                    <b> Web apps: </b>
                    Web apps are responsive versions of websites that can work
                    on any mobile device or OS because they’re delivered using a
                    mobile browser.{' '}
                  </li>{' '}
                  <li>
                    <b> Hybrid apps: </b>
                    Hybrid apps are combinations of both native and web apps,
                    but wrapped within a native app, giving it the ability to
                    have its own icon or be downloaded from an app store.{' '}
                  </li>{' '}
                </ul>{' '}
              </p>{' '}
            </Grid>{' '}
            <Grid item>
              <ButtonBase
                data-aos='zoom-in-left'
                data-aos-delay='200'
                sx={{
                  width: 500,
                  height: 500,
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 200,
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={MobileAppication}
                  sx={{
                    // marginTop: '20px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box className='Service-bg'>
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid item>
                <ButtonBase
                  data-aos='flip-up'
                  data-aos-delay='200'
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 345,
                      height: 200,
                      marginTop: '20px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={Ecommerce}
                    sx={{
                      // marginTop: '20px',
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='flip-up'
                data-aos-delay='200'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  E - Commerce Website{' '}
                </Typography>{' '}
                <br />
                <p className='About-accord '>
                  E-commerce websites are involved in the sale of goods or
                  services on the internet and electronically transferring funds
                  and data between two or more parties. We are experts at making
                  E - commerce website, a website that can help to process
                  orders, accept payments, manage shipping and logistics, and
                  provide customer service for their business. We are experts in
                  Business - to - consumer(B2C), Business - to - business(B2B),
                  Business - to - administration(B2A), Consumer - to -
                  consumer(C2C), Consumer - to - business(C2B), and Consumer -
                  to - administration(C2A).{' '}
                </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
        <br />
      </Box>{' '}
      <br />
      <br />
      <Box>
        <Container>
          <Grid container item xs={12} spacing={8}>
            {' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='fade-up-right'
              data-aos-delay='200'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Search Engine Optimization{' '}
              </Typography>{' '}
              <br />
              <p className='About-accord '>
                {' '}
                Search Engine Optimization(SEO) is a technique and methodology
                aimed at improving the website's visibility in the listings of
                search engines at the top.It is one of the crucial things for
                online marketing strategy. We are doing SEO, which brings your
                website to the top when online marketing efforts are not working
                up to the mark in quality traffic. The objective of SEO is to
                include both technical and creative elements required to enhance
                the rankings, drive traffic, and awareness of organization in
                search engines. Our SEO services have helped our customers on
                the basis of long-term and maintained the top rank forever.{' '}
              </p>{' '}
            </Grid>{' '}
            <Grid item>
              <ButtonBase
                data-aos='fade-up-left'
                data-aos-delay='200'
                sx={{
                  width: 500,
                  height: 500,
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 200,
                    marginTop: '0px',
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={SEO}
                  sx={{
                    // marginTop: '20px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
        <br />
      </Box>{' '}
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box className='Service-bg'>
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid item>
                <ButtonBase
                  data-aos='zoom-in'
                  data-aos-delay='200'
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 345,
                      height: 200,
                      marginTop: '40px',
                      marginBottom: '0px',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={FacebookMarketing}
                    sx={{
                      // marginTop: '20px',
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='zoom-in'
                data-aos-delay='200'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Facebook Marketing{' '}
                </Typography>{' '}
                <br />
                <p className='About-accord '>
                  In the present scenario, Facebook is a free marketing tool, a
                  champion and rules in the social media market, and for
                  creating buzz about business by listing product offering,
                  services, sharing links, images, and posts on a customizable
                  page to give a better response platform a business
                  personality. We create a Facebook group, which can be an
                  effective way to gather followers in one platform to interact
                  with each other to discuss your business to enhance
                  performance.{' '}
                </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
        <br />
      </Box>{' '}
      <br />
      <br />
      <Box>
        <Container>
          <Grid container item xs={12} spacing={8}>
            {' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='zoom-out-right'
              data-aos-delay='100'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Consulting Services{' '}
              </Typography>{' '}
              <br />
              <p className='About-accord '>
                In Vedsi, we give consulting services to the organization
                through discussion, diagnosis, planning, creating, and building
                a clickable, gathering, improving SEO, and sometimes even
                evaluating and helping to fix the problems. We are introducing
                our service consulting project. We very well know how to grab
                the opportunities for your business revenue's growth through
                customer interaction.{' '}
              </p>{' '}
            </Grid>{' '}
            <Grid item>
              <ButtonBase
                data-aos='zoom-out-left'
                data-aos-delay='200'
                sx={{
                  width: 500,
                  height: 500,
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 250,
                    marginTop: '-15px',
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={ConsultingServices}
                  sx={{
                    marginTop: '80px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                    },
                  }}
                />{' '}
                {''}{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box className='Service-bg'>
        <Container>
          <Grid container spacing={12} direction='column'>
            {' '}
            <Grid container item xs={12} spacing={8}>
              {' '}
              <Grid item>
                <ButtonBase
                  data-aos='flip-right'
                  data-aos-delay='100'
                  sx={{
                    width: 500,
                    height: 500,
                    '@media (max-width:600px)': {
                      width: 345,
                      height: 300,
                      marginTop: '20px',
                      // marginBottom: '-10px',
                      overflowY: 'hidden',
                    },
                  }}
                >
                  <Img
                    alt='complex'
                    src={AI}
                    sx={{
                      marginTop: '90px',
                      borderRadius: '15px',
                      '@media (max-width:600px)': {
                        padding: 0,
                      },
                    }}
                  />{' '}
                </ButtonBase>{' '}
              </Grid>{' '}
              <Grid
                item
                xs={12}
                sm={6}
                data-aos='flip-right'
                data-aos-delay='200'
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '1.7em',
                  textAlign: 'justify',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.7rem',
                    textAlign: 'center',
                    marginTop: '0px',
                    fontWeight: 'bolder',
                  }}
                >
                  {' '}
                  Artificial Intelligence & Machine Learning{' '}
                </Typography>{' '}
                <br /> {/* <p className='About-accord '></p> */}{' '}
                <p className='About-accord '>
                  We are always tried to do smart work with Artificial
                  Intelligence concepts in real - world environments, while in
                  Machine Learning we do work with new technologies and
                  algorithms that enable systems to identify patterns, make
                  decisions, and improve themselves through experience and data
                  for our customers. We effectively use AI & Machine learning to
                  automate tasks done by humans, including customer services
                  work, lead generation, fraund detection and quality control
                  for an organization.{' '}
                </p>{' '}
              </Grid>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
        <br />
      </Box>{' '}
      <br />
      <br />
      <Box>
        <Container>
          <Grid container item xs={12} spacing={8}>
            {' '}
            <Grid
              item
              xs={12}
              sm={6}
              data-aos='zoom-out-down'
              data-aos-delay='100'
              // data - aos = 'zoom-out-down'
              // data - aos - delay = '100'
              sx={{
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: '1.7em',
                textAlign: 'justify',
              }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  marginTop: '0px',
                  fontWeight: 'bolder',
                }}
              >
                {' '}
                Payroll Outsourcing{' '}
              </Typography>{' '}
              <br />
              <p className='About-accord '>
                We are offering payroll services to all sizes of organizations.
                We assure you that you will get all of your payroll needs on the
                same platform. We offer solutions for your payroll services,such
                as per government rules and regulations. We're offering payroll
                process, pay stub generation, employee salaries, taxation, audit
                payroll and send out an alert.{' '}
              </p>{' '}
            </Grid>{' '}
            <Grid item>
              <ButtonBase
                data-aos='zoom-out-down'
                data-aos-delay='200'
                sx={{
                  width: 500,
                  height: 500,
                  '@media (max-width:600px)': {
                    width: 345,
                    height: 200,
                    marginTop: '-10px',
                    marginBottom: '15px',
                  },
                }}
              >
                <Img
                  alt='complex'
                  src={Payroll}
                  sx={{
                    // marginTop: '20px',
                    borderRadius: '15px',
                    '@media (max-width:600px)': {
                      padding: 0,
                    },
                  }}
                />{' '}
              </ButtonBase>{' '}
            </Grid>{' '}
          </Grid>{' '}
        </Container>{' '}
      </Box>{' '}
    </Layout>
  )
}

export default Services
